import { graphql, Link } from "gatsby";
import React from "react"
import styled from "styled-components";
import Layout from "../components/layout"
import { small } from "../components/responsive";
import Seo from "../components/seo";
import { PageProps } from "../shared";
import { green } from "../shared/colors";

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.h2`
    padding: 20px;
    margin-bottom: 0;
`;

const SubHeader = styled.h4`
    margin-top: 0;
    padding: 0 20px 20px 20px;
`;

const Content = styled.div<{ odd: boolean }>`    
    display: flex;
    width: 100%;
    flex-direction: ${props => props.odd ? 'row-reverse' : 'row'};
    @media(max-width: ${small}) {
        flex-direction: column;
    }

    > * {
        background: white;
        flex: 0 0 50%;
        min-height: 300px;
    }

    > div  {
        display: flex;
        flex-direction: column;
        > p {
            text-align: ${props => props.odd ? 'right' : 'left'};
            flex: 1;
            @media(max-width: ${small}) {
                text-align: center;
            }
            &:before, &:after { 
                height: 2px;
                content: '';
                background: ${green};
                display: block;
                width: 100px;
                margin: 20px auto;
            }
        }
    }
`;

const ContentImage = styled.div<{ src: string }>`
    background: url(${props => props.src});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @media(max-width: ${small}) {
        width: 100%;
    }
`;

const ContentText = styled.p`
    position relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 2.5em;
    margin: 0;
    height: 100%;
    padding: 0 20px;
    white-space: pre-wrap;
`;

const ContactUs = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    font-size: 1.5em;
    &:before, &:after { 
        height: 2px;
        content: '';
        background: ${green};
        display: block;
        width: 100px;
        margin: 20px auto;
    }
`;

const WeddingVideo = styled.div`
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    width: 95%;

    > iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }
`;

const VideoBuffer = styled.div`
    height: 2px;
    content: '';
    background: ${green};
    display: block;
    width: 100px;
    margin: 20px auto;    
`;

type WeddingsProps = { headerImg: string, title: string, subtitle: string, sections: { text: string, image: string, video: string }[] };

export const query = graphql`
{
  pageData: weddingsJson {
    headerImg
    title
    subtitle
    sections {
      text
      image
      video
    }
  }
}
`;

const Weddings = (data: PageProps<WeddingsProps>) => {
    const { headerImg, title, subtitle, sections } = data.data.pageData;
    return (<>
        <Layout title="Wedding Hire" transparent={true} header={headerImg}>
            <Seo title='Wedding Hire' />
            <Wrapper>
                <Header>{title}</Header>
                <SubHeader>{subtitle}</SubHeader>
                {sections.map((s, index) => {
                    if (s.video != null && s.video.length > 0) {
                        return (
                            <><VideoBuffer />
                                <WeddingVideo key={index}>
                                    <iframe
                                        width="853"
                                        height="480"
                                        src={s.video}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        title="Embedded youtube"
                                    />
                                </WeddingVideo>
                                <VideoBuffer />
                            </>);
                    } else {
                        return (<Content odd={index % 2 === 1} key={index}>
                            <ContentImage src={s.image}></ContentImage>
                            <div>
                                <ContentText>{s.text}</ContentText>
                            </div>
                        </Content>)
                    }
                })}
                <ContactUs>
                    <div>
                        <Link to="/contact">Contact us</Link> for more information.
                    </div>
                </ContactUs>
            </Wrapper>
        </Layout>
    </>)
}

export default Weddings;